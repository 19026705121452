'use client'
import React, { useState } from 'react'
import useForm from '@/hooks/useForm'
import { CommonButton, CommonInput } from '@/components'
import { toast } from 'sonner'
import CommonTextArea from '@/components/common/CommonTextArea';
import Cookies from 'js-cookie';
import emailjs from '@emailjs/browser';
import { contactInputs } from '@/utils'

const textArea = {
  title: 'Mensaje',
  titleColor: 'text-white',
  value: '',
  name: 'message',
  type: "text",
  placeHolder: 'Escriba aqui...',
  minlength: '10',
  maxlength: '500',
  required: true
}

/**
 * @function Contact - This component is responsible for displaying a contact section with a form and email sending functionality using the EmailJS library.
 * The function sends the data entered by the user to Marine Broker's email.
 * It checks if the user has sent more than 3 emails per day using cookies, preventing spam.
 * The function provides feedback via toast notifications. 
 */
const Contact = () => {

  const [isSubmiting, setIsSubmiting] = useState(false)

  const { form, handleChange, resetForm } = useForm({
    user_name: '',
    user_email: '',
    message: ''
  })

  const sendEmail = async (e) => {
    e.preventDefault()
    setIsSubmiting(true)
    const emailSpam = Cookies.get('emails')
    if (emailSpam >= 3) {
      setIsSubmiting(false)
      return toast.error('No puedes enviar mas mails, intentalo mas tarde')
    } else {
      try {
        const response = await emailjs.send("service_e98xkuq", "template_m6hqsqx", form, 'gQ8sJoVzvDr6-TAtC');
        switch (response.status) {
          case 200:
            const moreSpam = Number(emailSpam) + 1
            const spamValue = moreSpam.toString()
            Cookies.set('emails', spamValue, { expires: 1 })
            return toast.success('Mail enviado con exito')
          default:
            return toast.error('Ocurrio un error en el envio de mail, intentalo mas tarde')
        }
      } catch (error) {
        return toast.error('Ocurrio un error en el envio de mail, intentalo mas tarde')
      } finally {
        setIsSubmiting(false)
      }
    }
  }

  return (
    <main id='contact' className='h-auto w-full flex flex-col md:flex-row py-20 gap-8 md:p-40 bg-gradient-to-r from-black to-[#0B0814] relative'>
      <span className=' bg-primary h-48 md:h-60 w-72 md:w-96 absolute md:bottom-0 md:left-0 blur-[200px]'></span>
      <section className='w-full md:w-1/2 h-min flex flex-col justify-around items-start p-4 md:p-0 md:pr-20 gap-y-8 '>
        <h1 className='text-4xl 2xl:text-5xl tracking-wide font-semibold text-white'>Contactanos</h1>
        <h4 className=' text-xs md:text-sm font-light text-start text-gray-100'>Para más información sobre nuestros servicios o para programar una consulta, por favor contáctenos. Estamos aquí para servirle y guiarle hacia la mejor protección del mercado.<br /> Gracias por considerar <span className='text-primary font-bold text-sm md:text-lg'>Marine Broker</span> para sus necesidades de seguros.</h4>
      </section>
      <section className='w-full md:w-1/2 h-min flex flex-col justify-around items-start px-8 gap-y-4'>
        <h2 className='font-semibold text-2xl xl:text-3xl text-white'>Envianos un <span className='text-primary'> mail</span></h2>
        <form onSubmit={sendEmail} className='w-full h-2/3 flex flex-col md:flex-row md:flex-wrap justify-between md:items-center gap-y-3'>
          {
            contactInputs.map(item => (
              <div key={item.name} className={` ${item.width} h-20 `}>
                <CommonInput values={item} onChange={handleChange} />
              </div>
            ))
          }
          <div className='w-full flex flex-col'>
            <CommonTextArea values={textArea} onChange={handleChange} />
          </div>
          <CommonButton disabled={isSubmiting} type='submit' text={isSubmiting ? 'Enviando...' : 'Enviar mensaje'} className={` ${isSubmiting && 'animate-pulse'} w-full mt-8 bg-primary text-white hover:bg-opacity-90 py-2 px-4`} />
        </form>
      </section>
    </main>
  )
}

export default Contact