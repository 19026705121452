'use client'
import Image from 'next/image'
import React from 'react'
import { attendanceCards } from '@/utils'

//This component is responsible for rendering a section that provides contact numbers for various insurers.
const Attendance = () => {

  return (
    <main className='w-full h-auto flex flex-col justify-center items-center relative gap-y-8' id='attendance'>
      <h1 className='text-center text-3xl md:text-4xl font-semibold'>En caso de emergencia, <span className=' text-primary'> actúa rápidamente.</span></h1>
      <p className='text-center text-xs md:text-sm font-medium px-8 md:w-2/3 xl:w-1/2'>Los números que te proporcionamos a continuación son esenciales para recibir asistencia inmediata. Te recomendamos tenerlos a mano y utilizarlos solo en situaciones de emergencia. </p>
      <section className='h-auto w-full lg:w-11/12 2xl:w-5/6 flex flex-col md:flex-row justify-center items-center flex-wrap md:items-start gap-8'>
        {
          attendanceCards.map(item => (
            <button type='button' key={item.company} className='w-72 md:w-2/5 lg:w-80 xl:w-72 2xl:w-80 h-96 lg:h-97 2xl:h-98 gap-4 flex flex-col justify-center items-center p-6 bg-white shadow-lg shadow-slate-300 rounded-lg hover:shadow-xl hover:shadow-slate-400 transition-all duration-300'>
              <div className='flex justify-center items-center h-2/6 w-full rounded-lg bg-white shadow-md'>
                <Image
                  src={item.companyImg}
                  alt='Telefono'
                  width={20}
                  height={100}
                  className='w-32 lg:w-36 2xl:w-40 '
                />
              </div>
              <div className='w-full flex flex-col h-3/6 gap-y-3'>
                {
                  item.data.map(element => (
                    <div className='flex flex-col items-center w-full h-auto font-medium' key={element.subtitle}>
                      <h3 className='text-xxs lg:text-xs 2xl:text-sm'>{element.title}</h3>
                      <a className='text-sm lg:text-base 2xl:text-lg font-semibold'>{element.subtitle}</a>
                    </div>
                  ))
                }
              </div>
              <div className='px-6 py-3 rounded-xl bg-primary text-white text-sm font-semibold'>
                Contactar compañía
              </div>
            </button>
          ))
        }
      </section>
    </main>
  )
}

export default Attendance