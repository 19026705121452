import { useState } from 'react';

const useForm = (initialform) => {

  const [form, setForm] = useState(initialform);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  };

  const resetForm = () => {
    setForm(initialform);
  };

  return {
    form,
    handleChange,
    resetForm
  };
};

export default useForm;