'use client'
import React from 'react'
import Image from 'next/image'

//This component is responsible for rendering the home section.
const Home = () => {

  return (
    <main className='w-full h-[91vh] flex flex-col justify-start items-center relative bg-[#0B0814]' id='home'>
      <Image
        src={'/backGrounds/puerto.jpg'}
        fill
        className='object-cover z-10'
        alt='bg image'
        quality={100}
      />
      <section className='z-10 h-full w-full flex flex-col justify-center items-center bg-black bg-opacity-40'>
        <Image
          src={'/marineBroker/TitleMarineBrokers.png'}
          width={800}
          height={100}
          alt='bg image'
          quality={100}
          className='w-80 lg:w-101 xl:w-103 2xl:w-104'
        />
      </section>
    </main>
  )
}

export default Home