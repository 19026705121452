'use client'
import React, { useState } from 'react';

const CommonTextArea = ({ values, onChange, className = '' }) => {

  const { value, name, title, titleColor, placeHolder, minlength, maxlength, type, required } = values
  
  const [textAreaValue, setTextAreaValue] = useState(value || '');
  
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const newValue = e.target.value;
    setTextAreaValue(newValue);
    let errorMessage = '';
    if (required && newValue.length === 0) {
      errorMessage = 'Este campo es obligatorio.';
    } else if (newValue.length < minlength) {
      errorMessage = `El mínimo de caracteres es ${minlength}.`;
    } else if (newValue.length > maxlength) {
      errorMessage = `El máximo de caracteres es ${maxlength}.`;
    }
    setError(errorMessage);
    onChange(e);
  };

  return (
    <>
      <label className="flex flex-col justify-center items-start relative font-semibold gap-y-2">
        <span className={`block text-xs ${titleColor} `}>{title}</span>
        <textarea
          onChange={handleChange}
          type={type}
          name={name}
          required={required}
          minLength={minlength}
          maxLength={maxlength}
          placeholder={placeHolder}
          value={textAreaValue}
          className={`w-full h-28 md:h-32 xl:h-44 p-2 text-xs font-semibold rounded text-black ring-1 ring-gray-300  ${error ? 'ring-red-500' : 'focus:ring-2 focus:ring-primary focus:outline-none'} ${className}`}
        ></textarea>
        {error && <p className='mt-2 text-pink-600 text-xs absolute -bottom-5'>{error}</p>}
      </label>
    </>
  );
}

export default CommonTextArea;
